import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import BuildUnavailableLayout from './BuildUnavailableLayout'
import { useReviveBuildByHostname } from './buildQueries'

export default function BuildUnavailable() {
  const router = useRouter()
  const {
    query: { domain, path = '/' },
  } = router
  const { status, error, build } = useReviveBuildByHostname(domain)

  let text = 'Searching for your deployment...'
  let title
  let actionText
  let actionHref
  let redirectingTo
  let loading = true

  if (status === 'error') {
    title = 'Deployment failed to restore'
    text = error
    loading = false
    actionText = 'Open Edgio dashboard'
    actionHref = '/'

    if (build) {
      actionText = 'Open deployment in Edgio console'
      actionHref = build.consoleUrl
    }
  }

  // We keep the same text while redirecting as it may happen that the config
  // is not propagated yet, which redirects back to this screen.
  // By not changing the text we reduce a bit the flicker.
  if (status === 'deploying' || status === 'online') {
    text =
      'Please wait a few seconds. We are restoring your deployment. You will be automatically redirected once it is ready.'
    if (status === 'online') {
      redirectingTo = new URL(path, `https://${domain}`).toString()
    }
  }

  useEffect(() => {
    if (redirectingTo) {
      // we want a 10s redirect delay because of the config propagation delay
      setTimeout(() => {
        window.location.replace(redirectingTo)
      }, 10000)
    }
  }, [redirectingTo])

  return (
    <BuildUnavailableLayout
      title={title}
      loading={loading}
      text={text}
      actionText={actionText}
      actionHref={actionHref}
    />
  )
}
